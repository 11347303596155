import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useChainId, useAccount, useBalance, useReadContract } from "wagmi";
import { ethers } from "ethers";
import {
  Provider,
  Signer,
  BrowserProvider,
  types,
  Contract,
  utils,
} from "zksync-ethers";
import {
  extractError,
  NOT_ENOUGH_FUNDS,
  RPC_ERROR,
  USER_DENIED,
  getErrorType,
} from "lib/transactionErrors";
import { ApolloClient, gql, InMemoryCache } from "@apollo/client";
import { SUBGRAPH_URL } from "config";

// images
import ImgHero from "../../assets/22c9a5c4-5628-40d1-9dbd-a7967883ad59.jpg";
import ImgFudBtn from "../../assets/static/imgs/fud_button.png";
import ImgWalletConnect from "../../assets/static/imgs/wallet-connect.png";
import ImgClickHere from "../../assets/static/imgs/click_here.png";
import ImgClickHere2 from "../../assets/static/imgs/click_her2e.png";
import ImgOnFlipping from "../../assets/static/imgs/OF.gif";
import ImgHeads from "../../assets/static/imgs/heads.png";
import ImgTails from "../../assets/static/imgs/tails.png";

// header & footer
import Header from "views/Header";
// import Footer from "views/Footer";

// contract
import CoinFlipAbi from "config/abis/CoinFlip.json";
import FeeTokenAbi from "config/abis/FeeToken.json";
import {
  ZKSYNC_TEST,
  paymasterParams_MainNet,
  paymasterParams_TestNet,
  feeTokenAddress_MainNet,
  feeTokenAddress_TestNet,
  CFContractAddress_Main,
  CFContractAddress_Test,
  ADDRESS_NULL,
  MIN_FEETOKEN_AMOUNT,
} from "config";

const Home = () => {
  // ----------------- useStates -------------------------------------
  const [headsOrTails, setHeadsOrTails] = useState(-1);
  const [betAmount, setBetAmount] = useState(0);
  const [curState, setCurState] = useState(0); // 0: readyToBet, 1: pending, 2: win 3: lost
  const [isConfirmingTx, setIsConfirmingTx] = useState(false);
  const [hasFeeToken, setHasFeeToken] = useState(false);
  const [playerRanking, setPlayerRanking] = useState<BettingAmountCumulative[]>(
    []
  );
  // -----------------------------------------------------------------

  // ----------------- interact with contract ------------------------
  const { openConnectModal } = useConnectModal();
  const chainId = useChainId();
  const account = useAccount();
  const myBalance = useBalance({
    address: account ? account.address! : ADDRESS_NULL,
  });

  const { data: feeTokenBalance } = useReadContract({
    address:
      chainId === ZKSYNC_TEST
        ? feeTokenAddress_TestNet
        : feeTokenAddress_MainNet,
    abi: FeeTokenAbi,
    functionName: "balanceOf",
    args: [account ? account.address! : ADDRESS_NULL],
    chainId: chainId,
  });

  // pool balance
  const { data: poolBalance } = useBalance({
    address:
      chainId === ZKSYNC_TEST ? CFContractAddress_Test : CFContractAddress_Main,
    query: {
      enabled: true,
      gcTime: 100,
      initialDataUpdatedAt: 100,
    },
  });

  // ether price in USD
  const { data: ethPrice } = useReadContract({
    address:
      chainId === ZKSYNC_TEST ? CFContractAddress_Test : CFContractAddress_Main,
    abi: CoinFlipAbi,
    functionName: "getEthByUSD",
    args: [ethers.parseEther("1")],
    chainId: chainId,
  });

  // ether of betAmount
  const { data: ethAmount } = useReadContract({
    address:
      chainId === ZKSYNC_TEST ? CFContractAddress_Test : CFContractAddress_Main,
    abi: CoinFlipAbi,
    functionName: "getEthByUSDWithFee",
    args: [ethers.parseEther(betAmount.toString())],
    chainId: chainId,
  });

  // log pool balance
  useEffect(() => {
    console.log("Pool balance:   ", poolBalance);
  }, [poolBalance]);

  // user's fee token balance
  useEffect(() => {
    if (!feeTokenBalance || (feeTokenBalance as bigint) < MIN_FEETOKEN_AMOUNT)
      setHasFeeToken(false);
    else setHasFeeToken(true);
  }, [feeTokenBalance, curState]);

  // display betting result
  useEffect(() => {
    if (curState === 2) {
      document.getElementsByClassName("heads-tails-text")[0].innerHTML =
        headsOrTails ? "Tails" : "Heads";
      document
        .getElementById("hero-image")!
        .setAttribute("src", headsOrTails ? ImgTails : ImgHeads);
    } else if (curState === 3) {
      document.getElementsByClassName("heads-tails-text")[0].innerHTML =
        headsOrTails ? "Heads" : "Tails";
      document
        .getElementById("hero-image")!
        .setAttribute("src", headsOrTails ? ImgHeads : ImgTails);
    } else {
      document.getElementById("hero-image")!.setAttribute("src", ImgHero);
    }
  }, [curState]);

  // Claim Fee Token
  async function claimFeeToken() {
    const browserProvider = new BrowserProvider(window.ethereum);
    const signer = Signer.from(
      await browserProvider.getSigner(),
      Number((await browserProvider.getNetwork()).chainId),
      Provider.getDefaultProvider(
        chainId === ZKSYNC_TEST ? types.Network.Sepolia : types.Network.Mainnet
      )
    );

    const cf_contract = new Contract(
      chainId === ZKSYNC_TEST ? CFContractAddress_Test : CFContractAddress_Main,
      CoinFlipAbi,
      signer
    );

    try {
      var tx = cf_contract.claimFeeTokens();

      console.log("faucet start ");
      const txResult = await (await tx).wait();
      console.log("faucet result: ", txResult);
      tryAgaino();
    } catch (e) {
      tryAgaino();
    }
  }

  // Do Betting
  async function doBet() {
    if (headsOrTails === -1 || betAmount < 1) {
      toast.error("Please select your guess and bet amount!");
      return;
    }

    if ((await myBalance).data!.value < (ethAmount as bigint)) {
      toast.error("Insufficient Balance!");
      return;
    }

    setCurState(1);

    //
    // ============================== For Paymaster =====================================
    const browserProvider = new BrowserProvider(window.ethereum);
    const signer = Signer.from(
      await browserProvider.getSigner(),
      Number((await browserProvider.getNetwork()).chainId),
      Provider.getDefaultProvider(
        chainId === ZKSYNC_TEST ? types.Network.Sepolia : types.Network.Mainnet
      )
    );

    const cf_contract = new Contract(
      chainId === ZKSYNC_TEST ? CFContractAddress_Test : CFContractAddress_Main,
      CoinFlipAbi,
      signer
    );

    try {
      var tx = cf_contract.doBet(
        headsOrTails,
        ethers.parseEther(betAmount.toString()),
        {
          from: account.address,
          value: ethAmount,
          customData: {
            gasPerPubdata: utils.DEFAULT_GAS_PER_PUBDATA_LIMIT,
            paymasterParams:
              chainId === ZKSYNC_TEST
                ? paymasterParams_TestNet
                : paymasterParams_MainNet,
          },
        }
      );
      // display confirming image
      tx.then(async (res: any) => {
        setIsConfirmingTx(true);
      }).catch((e: any) => {
        // handle later
      });

      // tx return value
      const txResult = await (await tx).wait();

      console.log("Unicorn result: ", txResult);

      // validate betting result
      if (txResult) {
        console.log("transcation returned: ", txResult);
        setIsConfirmingTx(false);
        for (var i = 0; i < txResult!.logs.length; i++) {
          const log = txResult!.logs[i];
          if (
            log.address
              .toLowerCase()
              .localeCompare(
                chainId === ZKSYNC_TEST
                  ? CFContractAddress_Test.toLowerCase()
                  : CFContractAddress_Main.toLowerCase()
              ) === 0
          ) {
            let bet_amount = Number(log.data.slice(0, 66));
            let result = Number(log.data.slice(67, 131));
            console.log(
              "----unicorn catched amount: ",
              bet_amount,
              " result : ",
              result
            );
            // if success
            if (result === 1) {
              toast.success(
                "Congratulations! You won " +
                  parseInt(ethers.formatUnits(bet_amount.toString(), 18)) * 2 +
                  " USD."
              );
              setCurState(2);
              return;
            }
            // if failed
            else if (result === 0) {
              toast.error(
                "Oops! You lost " +
                  parseInt(ethers.formatUnits(bet_amount.toString(), 18)) +
                  " USD."
              );
              setCurState(3);
              return;
            }
          }
        }
        toast.error(
          "Error!: Cannot decode return data.\nPlease check contract code whether it contains event."
        );
        tryAgain();
      }
    } catch (e) {
      //
      // tx error handler
      //
      console.log("eagle Error: \n", e);
      let failMsg;

      const [type] = extractError(e as any);
      switch (type) {
        case NOT_ENOUGH_FUNDS:
          failMsg =
            "There is not enough ETH in your account to send this transaction.";
          break;
        case USER_DENIED:
          failMsg = `Transaction was cancelled.`;
          break;
        case RPC_ERROR:
          failMsg =
            "Transaction failed due to RPC error. Please try changing the RPC url in your wallet settings.";
          break;
        default:
          failMsg = "Transaction failed.";
      }
      toast.error(failMsg);
      setCurState(0);
    }
  }
  // -----------------------------------------------------------------
  const resetPage = () => {
    window.location.reload();
  };

  const tryAgaino = () => {
    setTimeout(resetPage, 300); // delay .3s
  };

  const tryAgain = () => {
    setCurState(0); // delay .3s
  };

  const bettingContent = () => {
    //
    // Wallet Not Connected
    //
    if (account.isDisconnected) {
      return (
        <div className="click-to-wal-con">
          <button className="button-33" onClick={openConnectModal}>
            {" "}
            Connect Wallet
          </button>
        </div>
      );
    }

    //
    // Confirming transaction
    //
    if (isConfirmingTx) {
      return (
        <div className="modal-container">
          <div className="modal-bg"></div>
          <img src={ImgOnFlipping} alt="flipping" className="img-flipping" />
        </div>
      );
    }

    //
    // if user has no Fee token (less than MIN_FEETOKEN_AMOUNT)
    //
    if (!hasFeeToken) {
      console.log("Please faucet.");
      return (
        <div className="flip-content">
          <p className="paymastertext" style={{ color: "#efae19" }}>
            Go gasless! Pay once for endless gameplay with Paymaster, Powered by
            TxSync.
          </p>

          <div className="faucet">
            <button onClick={claimFeeToken}>
              <div className="btn-content">Start</div>
              <div className="shadow"></div>
            </button>
          </div>
        </div>
      );
    }

    if (curState === 0) {
      //
      // Ready to Bet
      //
      return (
        <div className="flip-content">
          <div className="heads-tails">
            <button
              className={headsOrTails === 0 ? "pressed" : ""}
              onClick={() => setHeadsOrTails(0)}
            >
              <div className="btn-content">heads</div>
              <div className="shadow"></div>
            </button>

            <button
              className={headsOrTails === 1 ? "pressed" : ""}
              onClick={() => setHeadsOrTails(1)}
            >
              <div className="btn-content">tails</div>
              <div className="shadow"></div>
            </button>
          </div>

          <p className="">FOR</p>

          <div className="usd-grid">
            <button
              className={betAmount === 1 ? "pressed" : ""}
              onClick={() => setBetAmount(1)}
            >
              <div className="btn-content">1 USD</div>
              <div className="shadow"></div>
            </button>
            <button
              className={betAmount === 5 ? "pressed" : ""}
              onClick={() => setBetAmount(5)}
            >
              <div className="btn-content">5 USD</div>
              <div className="shadow"></div>
            </button>
            <button
              className={betAmount === 10 ? "pressed" : ""}
              onClick={() => setBetAmount(10)}
            >
              <div className="btn-content">10 USD</div>
              <div className="shadow"></div>
            </button>
            <button
              className={betAmount === 15 ? "pressed" : ""}
              onClick={() => setBetAmount(15)}
            >
              <div className="btn-content">15 USD</div>
              <div className="shadow"></div>
            </button>
            <button
              className={betAmount === 20 ? "pressed" : ""}
              onClick={() => setBetAmount(20)}
            >
              <div className="btn-content">20 USD</div>
              <div className="shadow"></div>
            </button>
            <button
              className={betAmount === 25 ? "pressed" : ""}
              onClick={() => setBetAmount(25)}
            >
              <div className="btn-content">25 USD</div>
              <div className="shadow"></div>
            </button>
            <button
              className={betAmount === 30 ? "pressed" : ""}
              onClick={() => setBetAmount(30)}
            >
              <div className="btn-content">30 USD</div>
              <div className="shadow"></div>
            </button>
            <button
              className={betAmount === 40 ? "pressed" : ""}
              onClick={() => setBetAmount(40)}
            >
              <div className="btn-content">40 USD</div>
              <div className="shadow"></div>
            </button>
            <button
              className={betAmount === 50 ? "pressed" : ""}
              onClick={() => setBetAmount(50)}
            >
              <div className="btn-content">50 USD</div>
              <div className="shadow"></div>
            </button>
          </div>

          <button className="button-82-pushable" onClick={doBet}>
            <span className="button-82-shadow"></span>
            <span className="button-82-edge"></span>
            <span className="button-82-front text">PLAY</span>
          </button>
        </div>
      );
    }

    //
    // waiting for deposit
    //
    else if (curState === 1) {
      return (
        <div className="flip-content">
          <div className="status-bg textShineBlack">
            <div className="mt-11">
              <p className="lb-1st-line">waiting for</p>
              <p className="lb-2nd-line">DEPOSIT</p>
            </div>
            <p className="lb-sm-bets">
              {headsOrTails ? "tails" : "heads"} for {betAmount} USD
            </p>
          </div>
        </div>
      );
    }

    //
    // you win
    //
    else if (curState === 2) {
      return (
        <div className="flip-content">
          <div className="status-bg  ">
            <div className="mt-15 ">
              <p className="lb-win-lose  textShineBlack">YOU WIN</p>
            </div>
            <p className="lb-sm-bets textShineBlackbottom">
              {betAmount * 2} USD
            </p>
          </div>

          <button className="btn-try-again win mt-15" onClick={tryAgain}>
            try again
          </button>
        </div>
      );
    }

    //
    // you lost
    //
    else if (curState === 3) {
      return (
        <div className="flip-content">
          <div className="status-bg textShineBlack">
            <div className="mt-15">
              <p className="lb-win-lose">YOU LOST</p>
            </div>
            <p className="lb-sm-bets">{betAmount} USD</p>
          </div>

          <button className="btn-try-again lose " onClick={tryAgain}>
            try again
          </button>
        </div>
      );
    }
  };
  // -----------------------------------------------------------------

  //
  // Subgraph for player ranking
  //
  useEffect(() => {
    const fetchBettingHistory = async () => {
      try {
        const client = new ApolloClient({
          uri: SUBGRAPH_URL,
          cache: new InMemoryCache(),
        });

        const { data: bettingResult, error: error } = await client.query({
          query: gql(bettingHistory),
          variables: {},
          fetchPolicy: "cache-first",
        });

        if (bettingResult && !error) {
          const histories = bettingResult.bettingResults;
          const ranking = bettingResult.bettingAmountCumulatives;

          setPlayerRanking(ranking);

          // const _now = Number((Date.now() / 1000).toFixed(0));
          // const _proposalClosed: BettingHistory[] = histories.filter(
          //   (item: any) => _now > Number(item.end)
          // );
          console.log("histories : ", histories);
          console.log("ranking : ", ranking);
        }
        // setLoadingProposal("loaded");
      } catch (error) {
        console.error(error);
        // setLoadingProposal("failed");
      }
    };
    fetchBettingHistory();
  }, [curState]);

  interface BettingHistory {
    id: number;
    user: String;
    amount: number;
    result: Boolean;
    timestamp: number;
  }

  interface BettingAmountCumulative {
    id: number;
    totalAmount: string;
  }

  const bettingHistory = `
    query MyQuery {
      bettingResults(first: 10) {
        id
        user
        amount
        result
        timestamp
      }
      bettingAmountCumulatives(
        orderBy: totalAmount
        orderDirection: desc
        first: 10
        where: {_change_block: {number_gte: 34552708}}
      ) {
        id
        totalAmount
      }
    }
  `;
  
  // -----------------------------------------------------------------

  const getLeaderBoard = () => {
    return (
      <div className="leaderboard">
        <table className="tb-leaderboard">
          <tr>
            <th>No</th>
            <th className="row-player">Player</th>
            <th>Total Amount</th>
          </tr>
          {playerRanking.map((player, i) => {
             const playerId = player.id.toString();
             const shortenedId =
               playerId.substring(0, 4) + "..." + playerId.substring(playerId.length - 4);
            return (
              <tr>
                <td>{i + 1}</td>
                <td>
                  {shortenedId}
                </td>
                <td>
                  {Number(ethers.formatEther(player.totalAmount)).toFixed(0) +
                    "USD"}
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    );
  };

  return (
    <div className="cf-main-sec">
      <div className="hero-bg">
        <Header />
        <div className="cf-new-container">
          <div className="cf-hero-sec">
            <div className="pool-container">
              
              <div className="keep-in-pool">
                {"In Pool: "}
                {poolBalance
                  ? Number(ethers.formatEther(poolBalance!.value)).toFixed(4) +
                    "ETH"
                  : "0 ETH"}
                <br></br>
                {poolBalance && ethPrice
                  ? "(" +
                    (Number(poolBalance!.value) / Number(ethPrice)).toFixed(0) +
                    "USD)"
                  : "(0USD)"}
              </div>
                
              
            </div>
            <div className="heads-tails-text"></div>
            <a href="https://holdstation.exchange/paymaster " target="_blank" rel="noopener noreferrer">
              <img
                src={ImgHero}
                className="img-hero imageglow"
                alt="heroimg"
                id="hero-image"
              />
            </a>
            {bettingContent()}
            {getLeaderBoard()}
          </div>
        </div>
        <div className="cf-liear-bg"></div>
      </div>
    </div>
  );
};

export default Home;
