import "@rainbow-me/rainbowkit/styles.css";
import "./polyfills";
import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { zkSync, zkSyncSepoliaTestnet } from "wagmi/chains";

import App from "./App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const config = getDefaultConfig({
  appName: "Coin Flip Game",
  projectId: "YOUR_PROJECT_ID",
  chains: [
    zkSync,
    zkSyncSepoliaTestnet,
    ...(process.env.REACT_APP_ENABLE_TESTNETS === "true"
      ? [zkSyncSepoliaTestnet]
      : []),
  ],
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <App />
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
