import { Provider, utils, types, Wallet } from "zksync-ethers";
import { ethers } from "ethers";

export const INFURA_ID = "PkSg__OS-7f9zA6VGKy5UDEx1V28aD5-";
export const RPC_URL = "https://sepolia.era.zksync.dev";

export const SUBGRAPH_URL =
  "https://api.studio.thegraph.com/query/74895/sobcoinflip/v0.0.6";

export const ZKSYNC_TEST = 300; // testnet;
export const ZKSYNC_MAIN = 324; // mainnet;

export const DEFAULT_TOKEN_DECIMAL = 10 ** 18;
export const DEFAULT_GAS_LIMIT = 200000;
export const ADDRESS_NULL = "0x00000000000000000000000000000000";
export const MIN_FEETOKEN_AMOUNT = 1000000000; // 1 Gwei

//
// -------------- CoinFlip Contract -------------------------------------------------
//
export const CFContractAddress_Main =
  "0x4bB1225dC7d642D8D5c28f808ca65e7BFA7379e1";
export const CFContractAddress_Test =
  "0x09b85eF7f9c56268E8657bDC684Cd96742C0A6Ff";

//
// -------------- Paymaster configuration ---------------------------------------------
//
export const paymasterAddress_MainNet =
  "0x1Fabd3880701ab595B5ca975812c90AEF314792D";
export const paymasterAddress_TestNet =
  "0xB3Fa345f279B36a89d14068442506b9B7e523953";

export const feeTokenAddress_MainNet =
  "0x2Da171EDD377031058664d10fC978B00A1767708";
export const feeTokenAddress_TestNet =
  "0x44f38aE9e8A45269b78E00c8126E1F3E6fc57e9a";

export const paymasterParams_MainNet = utils.getPaymasterParams(
  paymasterAddress_MainNet,
  {
    type: "ApprovalBased",
    token: feeTokenAddress_MainNet,
    minimalAllowance: MIN_FEETOKEN_AMOUNT, // tx fee: 1 Gwei
    innerInput: new Uint8Array(),
  }
);

export const paymasterParams_TestNet = utils.getPaymasterParams(
  paymasterAddress_TestNet,
  {
    type: "ApprovalBased",
    token: feeTokenAddress_TestNet,
    minimalAllowance: MIN_FEETOKEN_AMOUNT, // tx fee: 1 Gwei
    innerInput: new Uint8Array(),
  }
);
