import React, { useState } from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import NewLogo from "../../assets/static/imgs/logo.png";

export const color_primary = "#c010f5";

const Header = () => {
  const [ShowMenus, setShowMenus] = useState(false);

  return (
    <header>
      <div className="cf-new-container">
        <div className="cf-header-sp">
          <div className="cf-myFlex">
          <img src={NewLogo} alt="" className="ns-newlogo" />
            <button
              className="cf-menus-btn"
              onClick={() => setShowMenus(!ShowMenus)}
            >
              {/* <img src={MenuBtn} alt="MenuBtn" /> */}
            </button>
            <ConnectButton />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
